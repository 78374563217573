import { render, staticRenderFns } from "./galerias.vue?vue&type=template&id=04aecc5e&scoped=true"
import script from "./galerias.vue?vue&type=script&lang=js"
export * from "./galerias.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04aecc5e",
  null
  
)

export default component.exports