<template>
    <div class="home">
        <Hero />
        <Brands />

        <menu1 id="menu1" />
        <menu2 id="menu2" />
        <menu3 id="menu3" />
        <menu4 id="menu4" />
        <ContactForm />


    </div>
</template>

<script>
import Hero from '@/components/atl/Hero.vue';
import Brands from '@/components/atl/Brands.vue';
import menu1 from '@/components/atl/menu1.vue';
import menu2 from '@/components/atl/menu2.vue';
import menu3 from '@/components/atl/menu3.vue';
import menu4 from '@/components/atl/menu4.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
    name: 'Home',
    components: {
        Hero,
        Brands,
        menu1,
        menu2,
        menu3,
        menu4,
        ContactForm
    }
};
</script>

<style scoped>
.fondo-circulos {
    background: url(~@/assets/fondo_puntos.png) repeat-y;
    background-size: contain;
    /* height: 600px; */
    /* overflow: hidden; */
}
</style>
