<template>
    <div class="bg-dark">
        <h1>Radio y Televisión</h1>
        <div class="subtitle">Ofrecemos soluciones efectivas para que tu mensaje llegue a tu audiencia.</div>
        <p>La publicidad en radio y televisión sigue siendo una poderosa herramienta para llegar a audiencias masivas y
            cautivar la atención del público amplio y objetivo en un mundo cada vez más digital.</p>
        <p>Acercamos tu marca a los mejores canales de radio y televisión, contamos con alcance en todo el NOA y NEA.
        </p>
        <div class="image-container">
            <img src="@/assets/atl/radio-portada.jpg" v-if="!$isMobile()"
                alt="Persona viendo televisión con anuncio de Expertos en Variedades">
            <img src="@/assets/atl/radio-portada-m.jpg" v-if="$isMobile()"
                alt="Persona viendo televisión con anuncio de Expertos en Variedades">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
 h1 {
     color: #009ee2;
     font-size: 40px;
     text-align: center;
     margin-bottom: 10px;
     margin-top: 60px;
 }

 .subtitle {
     text-align: center;
     font-size: 18px;
     margin-bottom: 20px;
     font-weight: bold;
 }

 p {
     text-align: center;
     font-size: 16px;
     line-height: 1.2;
   
     max-width:1000px;
     display: block;
     margin:0 auto;
     color: #656565;
     
 }

 .image-container {
    display: block;
     width: 100%;
     max-width: 1400px;
     margin: 20px auto;
 }

 img {
     width: 100%;
     height: auto;
     border-radius: 10px;
 }

 @media screen and (max-width: 768px) {
h1 {
        color: #009ee2;
        font-size: 40px;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 60px;
        margin-left: 20px;
        line-height: 1.2;
    }
    
        .subtitle {
            text-align: left;
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: bold;
            margin-left: 20px;
        }
    
        p {
            text-align: left;
            font-size: 18px;
            line-height: 1.2;
            margin-left: 20px;
    
            max-width: 90%;
            display: block;
            margin: 0 auto;
            color: #656565;
            margin-left: 20px;
    
        }
    
        .image-container {
            display: block;
            width: 90%;
            max-width: 1000px;
            margin: 20px auto;
          
        }
//         .image-container img{
// height: 300px;
//     object-fit: cover;
//         }
        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
 }

</style>