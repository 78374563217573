<template>
    <div>
        <!-- Filtros para mostrar todos, leídos y no leídos -->
        <div class="filters">
            <button @click="filtro = 'todos'" :class="{ active: filtro === 'todos' }">Todos</button>
            <button @click="filtro = 'leidos'" :class="{ active: filtro === 'leidos' }">Leídos</button>
            <button @click="filtro = 'no-leidos'" :class="{ active: filtro === 'no-leidos' }">No Leídos</button>
        </div>

        <!-- Ciclo para mostrar los contactos filtrados -->
        <div v-for="contacto in filtrados" :key="contacto.id" class="card">
            <!-- Botón para marcar como leído -->
            <button class="marcar-leido" @click="marcarComoLeido(contacto)" :disabled="contacto.leido ? true : false">
                Marcar como Leído
            </button>
            <div class="card-item"><strong>Fecha:</strong> {{ contacto.fecha }}</div>
            <div class="card-item"><strong>Origen:</strong> {{ contacto.origen }}</div>
            <div class="card-item"><strong>Nombre:</strong> {{ contacto.nombre }}</div>
            <div class="card-item"><strong>Teléfono:</strong> {{ contacto.telefono }}</div>
            <div class="card-item"><strong>Empresa:</strong> {{ contacto.empresa }}</div>
            <div class="card-item"><strong>Correo:</strong> {{ contacto.correo }}</div>
            <div class="card-item"><strong>Mensaje:</strong> {{ contacto.mensaje }}</div>
        </div>

        <!-- Paginación -->
        <div class="pagination">
            <button @click="fetchContactos(contactos.prev_page_url)" :disabled="!contactos.prev_page_url">
                Anterior
            </button>
            <span>Página {{ contactos.current_page }} de {{ contactos.last_page }}</span>
            <button @click="fetchContactos(contactos.next_page_url)" :disabled="!contactos.next_page_url">
                Siguiente
            </button>
        </div>
    </div>
</template>
<script>
import { HTTP } from "@/axios";

export default {
    data() {
        return {
            contactos: {},
            filtro: 'todos', // Estado para el filtro
        };
    },
    computed: {
        filtrados() {
            if (this.filtro === 'leidos') {
                return this.contactos.data.filter(contacto => contacto.leido);
            } else if (this.filtro === 'no-leidos') {
                return this.contactos.data.filter(contacto => !contacto.leido);
            } else {
                return this.contactos.data;
            }
        }
    },
    created() {
        this.fetchContactos();
    },
    methods: {
        fetchContactos() {
            HTTP.get('/contactos').then(response => {
                this.contactos = response.data;
            }).catch(error => {
                console.error('Error fetching contactos:', error);
            });
        },
        marcarComoLeido(contacto) {
            HTTP.post(`/contactos/${contacto.id}/marcar-como-leido`)
                .then(res => {
                    console.log(res);
                    contacto.leido = true;
                    this.$toast.success("Mensaje marcado correctamente");
                })
                .catch(error => {
                    console.error('Error marcando como leído:', error);
                    this.$toast.error("No se pudo procesar la solicitud, reintente");
                });
        }
    }
}
</script>

<style scoped>
.card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
}

.card-item {
    margin-bottom: 10px;
}

.marcar-leido {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.marcar-leido:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.filters {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}

.filters button {
    padding: 10px 15px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
    border-radius: 5px;
}

.filters button.active {
    background-color: #007bff;
    color: white;
}

.pagination {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
    .card {
        padding: 10px;
        font-size: 14px;
    }
}
</style>
