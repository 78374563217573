import axios from 'axios'
export const baseUrl = `https://gestor.quomarketing.com.ar/public/api`
// export const baseUrl = `http://promo.test/`
// en caso de usar las variables de entorno de development y production descomentar lo de abajo
// export const baseUrl = process.env.VUE_APP_BASE_URL
// export const imgUrl = ``
export const HTTP = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  crossDomain: true,
})
