<template>
    <div>
        <h1>Prensa y Pauta de Medios</h1>
        <div class="subtitle">Elegimos y gestionamos los espacios de medios, y definimos el contenido promocional de tu
            marca.</div>
        <p>Contamos con un equipo de profesionales capacitados para desarrollar y poner en marcha estrategias de
            comunicación para destacar tu marca en los diferentes medios.
        </p>
        <p>
            Manejamos una amplia red de contactos en medios y relaciones públicas al servicio de cada cliente, para
            convertirlos en referentes de la industria.
        </p>
        <section class="images-section" v-if="!$isMobile()">
            <img src="@/assets/atl/prensa-1.jpg" alt="Prensa " style="cursor:pointer" class="info-image"
                @click="openVideo">
            <img src="@/assets/atl/prensa-2.jpg" alt="Prensa" class="info-image">
            <img src="@/assets/atl/prensa-3.jpg" alt="Prensa" class="info-image">
        </section>


        <div v-if="$isMobile()">
            <swiper class="swiper1" :options="swiperOption">
                <swiper-slide> <img src="@/assets/atl/prensa-1.jpg" alt="Prensa " class="info-image-m"
                        @click="openVideo"></swiper-slide>
                <swiper-slide> <img src="@/assets/atl/prensa-2.jpg" alt="Prensa" class="info-image-m"></swiper-slide>
                <swiper-slide> <img src="@/assets/atl/prensa-3.jpg" alt="Prensa" class="info-image-m"></swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <modal-video :channel="videoChannel" :videoId="videoId" :isOpen.sync="videoIsOpen" />

    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ModalVideo from '@/components/modal-video'


export default {
    name: 'swiper-example-pagination',
    title: 'Pagination',
    components: {
        Swiper,
        SwiperSlide,
        ModalVideo
    },
    data() {
        return {
            dialogVisible: false,
            videoUrl: '',
            videoIsOpen: false,
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination'
                }
            }
        }
    },
    computed: {
        videoChannel: function () {
            //Logic to extract based on URL
            return 'youtube'
        },
        videoId: function () {
            //Logic to extract based on URL
            return 'HffsrxWC_fw'
        }
    },
    methods: {
        openVideo() {
            this.videoIsOpen = !this.videoIsOpen
        },
        openDialog(url) {
            this.videoUrl = url;
            this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
            this.videoUrl = '';
        }
    }
}
</script>

<style lang="scss" scoped>
/* Modal con CSS puro */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    // background-color: #fff;
    padding: 0px;
    border-radius: 5px;
    width: 80%;
    max-width: 700px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #aaa;
}

.close:hover {
    color: #000;
}
.swiper1 {
    height: 250px;
    width: 100%;
    margin-top: 20px;
}

.images-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 80px auto;
    gap: 5px;
    margin-left: -32px;
    z-index: 2;
    position: relative;
    margin-top: 80px;
    /* Espacio entre las imágenes */
}

.info-image-m {
    width: 100%;
        height: 200px;
        object-fit: cover;
}

.info-image {
    flex: 1;
    height: auto;
    object-fit: cover;
    width: calc(33.333% - 10px);
    /* Calcula el ancho de cada imagen restando el espacio entre ellas */
}

h1 {
    color: #009ee2;
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 80px;
}

.subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
}

p {
    text-align: center;
    font-size: 16px;
    line-height: 1.2;

    max-width: 1000px;
    display: block;
    margin: 0 auto;
    color: #656565;

}

.image-container {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 80px auto;
}


@media screen and (max-width: 768px) {
    .images-section {
        flex-direction: column;
    }

    .info-image {
        width: 100%;
        margin: 5px 0;
    }

    .container {

        padding: 20px;
    }

    .title {

        max-width: 100%;

    }

    .info-box {
        width: 100%;

        padding: 0px;

    }

    .info-box-r {
        width: 100%;

        padding: 0px;

        float: left;
    }

    .info-title {
        font-weight: 700;
        font-size: 18px;
        z-index: 1;
        position: relative;

    }

    .info-section {
        /* display: flex;
                                    justify-content: space-between; */
        padding: 0;
        margin-bottom: 40px;
        position: relative;
    }


}

@media screen and (max-width: 768px) {
    h1 {
        color: #009ee2;
        font-size: 40px;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 60px;
        margin-left: 20px;
        line-height: 1.2;
    }

    .subtitle {
        text-align: left;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
        margin-left:20px;
        width: 90%;
    }

    p {
        text-align: left;
        font-size: 18px;
        line-height: 1.2;
        margin-left: 20px;

        max-width: 90%;
        display: block;
        margin: 0 auto;
        color: #656565;
        margin-left: 20px;

    }

    .image-container {
        display: block;
        width: 80%;
        max-width: 1000px;
        margin: 20px auto;

    }

    //         .image-container img{
    // height: 300px;
    //     object-fit: cover;
    //         }

}
</style>