<template>
    <div>
        <h1>Desarrollo de imagen en PDV</h1>
        <div class="subtitle">¡Hacemos que tu marca se destaque!</div>
        <p>En Quo nos encargamos de destacar tu marca en los puntos de contacto con el consumidor.
            <br>
            Nuestro servicio
            incluye el relevamiento, diseño, producción, e instalación de cartelería y elementos publicitarios en los
            puntos de venta.
        </p>

        <section class="images-section" v-if="!$isMobile()">
            <img src="@/assets/trade/pdv-1.jpg" alt="Local destacado " class="info-image">
            <img src="@/assets/trade/pdv-2.jpg" alt="Local destacado " class="info-image">
            <img src="@/assets/trade/pdv-3.jpg" alt="Local destacado " class="info-image">
        </section>


        <div v-if="$isMobile()">
            <swiper class="swiper1" :options="swiperOption">
                <swiper-slide> <img src="@/assets/trade/pdv-1.jpg" alt="Local destacado "
                        class="info-image-m"></swiper-slide>
                <swiper-slide> <img src="@/assets/trade/pdv-2.jpg" alt="Local destacado "
                        class="info-image-m"></swiper-slide>
                <swiper-slide> <img src="@/assets/trade/pdv-3.jpg" alt="Local destacado "
                        class="info-image-m"></swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'swiper-example-pagination',
    title: 'Pagination',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.swiper1 {
    height: 250px;
    width: 100%;
    margin-top: 20px;
}

.images-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 80px auto;
    gap: 5px;
    margin-left: -32px;
    z-index: 2;
    position: relative;
    margin-top: 0px;
    /* Espacio entre las imágenes */
}

.info-image-m {
    /* display: block;
    width: 100%; */
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.info-image {
    flex: 1;
    height: auto;
    object-fit: cover;
    width: calc(33.333% - 10px);
    /* Calcula el ancho de cada imagen restando el espacio entre ellas */
}

h1 {
    color: #009ee2;
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 80px;
}

.subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
}

p {
    text-align: center;
    font-size: 16px;
    line-height: 1.2;

    max-width: 1000px;
    display: block;
    margin: 0 auto;
    color: #656565;

}

.image-container {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 80px auto;
}


@media screen and (max-width: 768px) {
    .images-section {
        flex-direction: column;
    }

    .info-image {
        width: 100%;
        margin: 5px 0;
    }

    .container {

        padding: 20px;
    }

    .title {

        max-width: 100%;

    }

    .info-box {
        width: 100%;

        padding: 0px;

    }

    .info-box-r {
        width: 100%;

        padding: 0px;

        float: left;
    }

    .info-title {
        font-weight: 700;
        font-size: 18px;
        z-index: 1;
        position: relative;

    }

    .info-section {
        /* display: flex;
                                    justify-content: space-between; */
        padding: 0;
        margin-bottom: 40px;
        position: relative;
    }


}

@media screen and (max-width: 768px) {
    h1 {
        color: #009ee2;
        font-size: 40px;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 60px;
        margin-left: 20px;
        line-height: 1.2;
    }

    .subtitle {
        text-align: left;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
        margin-left: 20px;
        width: 90%;
    }

    p {
        text-align: left;
        font-size: 18px;
        line-height: 1.2;
        margin-left: 20px;

        max-width: 90%;
        display: block;
        margin: 0 auto;
        color: #656565;
        margin-left: 20px;

    }

    .image-container {
        display: block;
        width: 80%;
        max-width: 1000px;
        margin: 20px auto;

    }

    //         .image-container img{
    // height: 300px;
    //     object-fit: cover;
    //         }

}
</style>