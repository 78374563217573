import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
// import About from './views/About.vue';
import ATL from '@/views/atl.vue';
import btl from '@/views/btl.vue';
import trade from '@/views/trade.vue';
import proyectos from '@/views/proyectos.vue';
import merchandising from '@/views/merchandising.vue';
import logistica from '@/views/logistica.vue';
import Admin from '@/views/Admin.vue';
import Login from '@/views/Login.vue';

import mensajes from '@/components/admin/mensajes.vue';
import datos from '@/components/admin/datos.vue';
import textos from '@/components/admin/textos.vue';
import imagenes from '@/components/admin/imagenes.vue';
import galerias from '@/components/admin/galerias.vue';


// import Contact from './views/Contact.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'home',component: Home },
    { path: '/servicios/atl', name: 'atl', component: ATL },
    { path: '/servicios/btl', name: 'btl', component: btl },
    { path: '/servicios/trade-marketing', name: 'trade', component: trade },
    { path: '/servicios/proyectos-especiales', name: 'proyectos', component: proyectos },
    { path: '/servicios/merchandising', name: 'merchandising', component: merchandising },
    { path: '/servicios/logistica', name: 'log', component: logistica },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/admin',
      component: Admin,
      children: [
        {
          path: 'datos',
          component: datos
        },
        {
          path: 'mensajes',
          component: mensajes
        },
        {
          path: 'textos',
          component: textos
        },
        {
          path: 'imagenes',
          component: imagenes
        },
        {
          path: 'galerias',
          component: galerias
        },
      ]
    }
   
  ]
});

// Simulación de autenticación
router.beforeEach((to, from, next) => {
  const isAuthenticated = window.sessionStorage.getItem('authQuo'); // Usar un sistema de autenticación real en producción
  if (to.path.startsWith('/admin') && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;