<template>
    <div class="login-container">
        <div class="login-card">
            <h2>Administración</h2>
            <form @submit.prevent="handleLogin">
                <div class="form-group">
                    <input v-model="username" placeholder="Usuario" required />
                </div>
                <div class="form-group">
                    <input v-model="password" type="password" placeholder="Password" required />
                </div>
                <button type="submit">Ingresar</button>
            </form>
            <p v-if="errorMessage">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            errorMessage: ''
        };
    },
    methods: {
        handleLogin() {
            // Simulación de autenticación
            if (this.username === 'admin' && this.password === 'Fg9685425*') {
                window.sessionStorage.setItem('authQuo', true);
                this.$router.push('/admin/datos');
            } else {
                this.errorMessage = 'Datos incorrectos';
            }
        }
    }
};
</script>

<style scoped>
.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
}

.login-card {
    background: white;
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.form-group {
    margin-bottom: 1em;
}

input {
    width: 100%;
    padding: 0.8em;
    margin-bottom: 1em;
    border-radius: 4px;
    border: 1px solid #ddd;
}

button {
    width: 100%;
    padding: 0.8em;
    background-color: #42b983;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #359a6c;
}

p {
    color: red;
}
</style>
