<template>
    <div class="about-home" id="about">
        <div class="container">
            <header class="header">
                <h2 class="subtitle">¿BUSCAS CONECTAR CON TUS CLIENTES POTENCIALES ?</h2>
                <h1 class="title">En Quo nos enfocamos en tus desafíos y <span class="highlight">objetivos a
                        cumplir.</span>
                </h1>
            </header>

            <section class="info-section">
                <div class="info-box">
                    <h3 class="info-title"><span class="material-symbols-outlined">
                            arrow_forward
                        </span> ¿Quiénes somos?</h3>
                    <p class="info-text">Somos una empresa creativa, especializada en <br v-if="!$isMobile()"> la
                        elaboración de planes de
                        Marketing <br v-if="!$isMobile() ">
                        que tu empresa necesita.</p>
                </div>
                <div class="info-box-r">
                    <h3 class="info-title"><span class="material-symbols-outlined">
                            arrow_forward
                        </span> ¿Qué podemos hacer por vos?</h3>
                    <p class="info-text">Ofrecemos diferentes servicios adaptados a tu <br v-if="!$isMobile() "> empresa,
                        producto y a las
                        necesidades <br v-if="!$isMobile() ">
                        específicas de la marca.</p>
                </div>
            </section>
        </div>
        <section class="images-section" v-if="!$isMobile()">
            <img src="@/assets/home/team-metting.jpg" alt="Team Meeting" class="info-image">
            <img src="@/assets/home/quo.jpg" alt="Quo Marketing" class="info-image">
            <img src="@/assets/home/office.jpg" alt="Office" class="info-image">
        </section>
        <img src="@/assets/semi-circulo.png" alt="" class="fondo-sc">

        <div v-if="$isMobile() ">
            <swiper class="swiper1" :options="swiperOption">
                <swiper-slide> <img src="@/assets/home/team-metting.jpg" alt="Team Meeting"
                        class="info-image-m"></swiper-slide>
                <swiper-slide> <img src="@/assets/home/quo.jpg" alt="Quo Marketing" class="info-image-m"></swiper-slide>
                <swiper-slide> <img src="@/assets/home/office.jpg" alt="Office" class="info-image-m"></swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>


    </div>

</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'swiper-example-pagination',
    title: 'Pagination',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination'
                }
            }
        }
    }
}
</script>
<style scoped>
.swiper1{
    height: 280px;
    width: 100%;
}
.fondo-sc{
    position: absolute;
    bottom:-100px;
    left: 0;
    width: 26%;
    z-index: 0;
}
.about-home{
    position: relative;
    padding-top: 50px;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
}

.header {
    text-align: center;
    margin-bottom: 40px;
}

.subtitle {
    color: #098BCC;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
}

.title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.3;
    color: #272838;
    max-width: 75%;
    text-align: center;
    margin:0 auto;
}

.highlight {
    color: #098BCC;
}

.info-section {
    /* display: flex;
    justify-content: space-between; */
    margin-bottom: 40px;
}

.info-box {
    width: 48%;
   
    padding: 20px;
    border-radius: 10px;
}
.info-box-r {
    width: 48%;

    padding: 20px;
    border-radius: 10px;
    float: right;
}
.info-title {
    font-weight: 700;
    font-size: 20px;
    color: #272838;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.info-title span {
    color: #098BCC;
    margin-right: 8px;
    /* Espacio entre el icono y el texto */
}
.info-text {
    font-size: 16px;
    color: #656565;
    line-height: 1.6;
    z-index:2;
    position: relative;
    font-weight: 300;
}

.images-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    gap: 5px;
    margin-left: -32px;
    z-index:2;
    position: relative;
    /* Espacio entre las imágenes */
}

.info-image-m{
    /* display: block;
    width: 100%; */
    width: 100%;
}
.info-image {
    flex: 1;
    height: auto;
    object-fit: cover;
    width: calc(33.333% - 10px);
    /* Calcula el ancho de cada imagen restando el espacio entre ellas */
}

@media screen and (max-width: 768px) {
    .images-section {
        flex-direction: column;
    }

    .info-image {
        width: 100%;
        margin: 5px 0;
    }
        .container {
          
            padding: 20px;
        }
                .title {
               
                    max-width: 100%;
                
                }

                                .info-box {
                                    width: 100%;
                
                                    padding: 0px;
                                  
                                }
                
                                .info-box-r {
                                    width: 100%;
                
                                    padding: 0px;
                                   
                                    float: left;
                                }
                
                                .info-title {
                                    font-weight: 700;
                                    font-size: 18px;
                                    z-index: 1;
                                    position: relative;
                                   
                                 
                                }
                                                                .info-text {
                                                                    font-size: 15px;
                                                                    color: #656565;
                                                                    line-height: 1.6;
                                                                    z-index: 2;
                                                                    position: relative;
                                                                    font-weight: 300;
                                                                    margin-bottom: 40px;
                                                                    
                                                                }
                                                                .info-section {
                                                                    /* display: flex;
                                    justify-content: space-between; */
                                    padding: 0;
                                                                    margin-bottom: 40px;
                                                                    position: relative;
                                                                }
                
                             
}
@media (prefers-color-scheme: dark) {
    .about-home{
        background-color: #000;
    }
    .fondo-sc{
        display: none;
    }
    .title{
        color:#E2E2E2
    }
    .info-title{
        color: #098BCC;
    }
    .info-text{
        color: #E2E2E2;
    }
}
</style>
