<template>
    <div>
        <h1>Diseños personalizados</h1>
        <div class="subtitle">Entendemos que cada marca es única, por eso adaptamos nuestros diseños a tus necesidades
            específicas</div>
        <p>¿Buscas algo único y memorable? Nuestro equipo de diseñadores expertos está aquí para ayudarte a crear
            productos personalizados que reflejen la identidad de tu marca y se destaquen en cualquier entorno.
            Trabajamos en cada paso del proceso para garantizar resultados excepcionales
        </p>
        <div class="image-container">
            <ImageSlider :carpeta="'merch/disenios'" />


        </div>
    </div>
</template>

<script>
import ImageSlider from '@/components/ImageSlider.vue'

export default {
    components: {
        ImageSlider
    }
}
</script>

<style lang="scss" scoped>
h1 {
    color: #009ee2;
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 80px;
}

.subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
}

p {
    text-align: center;
    font-size: 16px;
    line-height: 1.2;

    max-width: 1000px;
    display: block;
    margin: 0 auto;
    color: #656565;

}

.image-container {
    display: block;
    width: 96%;
    max-width: 1400px;
    margin: 30px auto;
}

img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media screen and (max-width:768px) {
    .image-container {
        display: block;
        width: 90%;
        max-width: 93%;
        margin: 10px auto;
    }
}

@media screen and (max-width: 768px) {
    h1 {
        color: #009ee2;
        font-size: 40px;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 60px;
        margin-left: 20px;
        line-height: 1.2;
    }

    .subtitle {
        text-align: left;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
        margin-left: 20px;
        max-width: 90%;
    }

    p {
        text-align: left;
        font-size: 18px;
        line-height: 1.2;
        margin-left: 20px;

        max-width: 90%;
        display: block;
        margin: 0 auto;
        color: #656565;
        margin-left: 20px;

    }


    //         .image-container img{
    // height: 300px;
    //     object-fit: cover;
    //         }
    img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
}
</style>