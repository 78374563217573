import { render, staticRenderFns } from "./imagenes.vue?vue&type=template&id=53ae9999&scoped=true"
import script from "./imagenes.vue?vue&type=script&lang=js"
export * from "./imagenes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ae9999",
  null
  
)

export default component.exports