<template>
  <div id="app">
    <HeaderQuo />

    <transition name="fade">
      <div id="pagetop" class="fixed right-0 bottom-0" v-show="scY > 600" @click="toTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#4a5568"
          stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
          <path d="M18 15l-6-6-6 6" />
        </svg>
      </div>
    </transition>
    <router-view></router-view>
    <FooterQuo />
    <simplert />

  </div>
</template>

<script>
import HeaderQuo from '@/components/HeaderQuo.vue';
import FooterQuo from '@/components/FooterQuo.vue';

export default {
  name: 'App',
  components: {
    HeaderQuo,
    FooterQuo
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
};
</script>

<style>
/* Agrega estilos globales si es necesario */
.fixed{
  position: fixed;
  right: 0;
  bottom: 320px;
  cursor: pointer;
  z-index:10;
}
</style>
