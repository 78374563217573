var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('nav',[_vm._m(0),_c('div',{class:['menu-toggle', { 'open': _vm.menuOpen }],on:{"click":_vm.toggleMenu}},[_c('div',{staticClass:"bar",class:{ 'open': _vm.menuOpen }}),_c('div',{staticClass:"bar",class:{ 'open': _vm.menuOpen }}),_c('div',{staticClass:"bar",class:{ 'open': _vm.menuOpen }})]),_c('ul',{class:{ 'menu-open': _vm.menuOpen }},[_c('li',[(_vm.$isMobile() )?_c('a',{attrs:{"href":"#"},on:{"click":_vm.navigateAndScroll1}},[_vm._v("Home")]):_vm._e()]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":_vm.navigateAndScroll}},[_vm._v("Sobre Nosotros")])]),(!_vm.$isMobile() )?_c('li',{staticClass:"dropdown",on:{"mouseover":_vm.openDropdown}},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#servicios'),expression:"'#servicios'"}],attrs:{"href":"#"}},[_vm._v("Nuestros Servicios")]),_c('ul',{staticClass:"dropdown-content",class:{ 'dropdown-content-open': _vm.dropdownOpen },on:{"mouseleave":_vm.closeDropdown}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])]):_vm._e(),(_vm.$isMobile())?_c('li',{staticClass:"dropdown",on:{"mouseover":_vm.openDropdown}},[_c('a',{attrs:{"href":"#"}},[_vm._v("Nuestros Servicios")]),_c('ul',{staticClass:"dropdown-content",class:{ 'dropdown-content-open': _vm.dropdownOpen },on:{"mouseleave":_vm.closeDropdown}},[_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])]):_vm._e(),_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#contact-form'),expression:"'#contact-form'"}],staticClass:"contact-button",attrs:{"href":"#"}},[_vm._v("Contáctanos")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"/","title":"Volver al home"}},[_c('img',{attrs:{"src":require("@/assets/logo-quo.png"),"alt":"Logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/atl"}},[_vm._v("ATL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":" /servicios/btl"}},[_vm._v("BTL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/trade-marketing"}},[_vm._v("Trade Marketing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/proyectos-especiales"}},[_vm._v("Proyectos Especiales")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/merchandising"}},[_vm._v("Merchandising")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/logistica"}},[_vm._v("Logística")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/atl"}},[_vm._v("ATL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":" /servicios/btl"}},[_vm._v("BTL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/trade-marketing"}},[_vm._v("Trade Marketing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/proyectos-especiales"}},[_vm._v("Proyectos Especiales")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/merchandising"}},[_vm._v("Merchandising")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/servicios/logistica"}},[_vm._v("Logística")])])
}]

export { render, staticRenderFns }