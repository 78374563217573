<template>
    <div style="position:relative" id="contact-form">
        <div class="contact-form">
            <h1>Estamos aquí para ser <span class="highlight">parte de tu éxito</span></h1>
            <p class="subtitle">
                Nuestro equipo está siempre listo para ofrecerte soluciones creativas y efectivas que impulsarán tu <br>
                empresa y maximizarán tus resultados. <strong>¡Contáctanos!</strong>
            </p>
            <form @submit.prevent="submitForm">
                <div class="form-row">
                    <div class="input-group">
                        <label for="nombre">Nombre y apellido</label>
                        <input type="text" id="nombre" v-model="nombre" required>
                    </div>
                    <div class="input-group">
                        <label for="telefono">Número de teléfono</label>
                        <input type="tel" id="telefono" v-model="telefono" required>
                    </div>
                </div>
                <div class="form-row">
                    <div class="input-group">
                        <label for="empresa">Empresa</label>
                        <input type="text" id="empresa" v-model="empresa" required>
                    </div>
                    <div class="input-group">
                        <label for="correo">Correo electrónico</label>
                        <input type="email" id="correo" v-model="correo" required>
                    </div>
                </div>
                <div class="input-group full-width">
                    <label for="mensaje">Mensaje</label>
                    <textarea id="mensaje" placeholder="Escriba su mensaje aquí" v-model="mensaje" required></textarea>
                </div>
                <!-- <button type="submit">Enviar mensaje</button> -->
                <button type="submit"  :disabled="isLoading" class="loading-button" >
                    <span v-if="isLoading" class="spinner"></span>
                    <span v-else>{{ buttonText }}</span>
                </button>
            </form>
        </div>
        <img src="@/assets/semi-circulo.png" alt="" class="fondo-sc">
      
    </div>

</template>

<script>
import {HTTP} from "@/axios"

export default {
    // components: { Simplert },

    data() {
        return {
            nombre: '',
            telefono: '',
            empresa: '',
            correo: '',
            mensaje: '',
            origen:'home',
            isLoading: false,
            buttonText: 'Enviar mensaje',
        }
    },
    methods: {
        resetForm() {
            this.nombre = '';
            this.telefono = '';
            this.empresa = '';
            this.correo = '';
            this.mensaje = '';
        },
        handleClick() {
            this.isLoading = true;
            // Simulación de una tarea asíncrona
            setTimeout(() => {
                this.isLoading = false;
                // Realiza la acción real aquí
            }, 2000); // Simula 2 segundos de carga
        },
        submitForm() {
            // Handle form submission logic here
            console.log('Form submitted', this.$data)
            this.isLoading = true;
            HTTP.post('/enviarContacto', this.$data)
                .then(() => {
                    this.resetForm()
                    this.isLoading = false;
                    let obj = {
                       
                        message: 'Recibimos tu mensaje, pronto nos pondremos en contacto. Muchas gracias por comunicarse',
                        type: 'success',
                        
                    }
                    this.$Simplert.open(obj);

                    // this.$refs.simplert.openSimplert(obj)
                })
                .catch(() => {
                    this.resetForm()
                    this.isLoading = false;
                    let obj = {
                       
                        message: 'No se pudo enviar el mensaje, refresque la página y reintente',
                        type: 'error',
                      
                    }
                    this.$Simplert.open(obj);

                })
        }
    }
}
</script>

<style scoped>
.loading-button {
    position: relative;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.loading-button:disabled {
    background-color: #5a6268;
    cursor: not-allowed;
}

.spinner {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.fondo-sc {
    position: absolute;
    bottom: 250px;
    right: 0;
    width: 26%;
    z-index: 0;
    transform: rotate(180deg);
}
form{
    max-width: 800px;
    margin: 20px auto;
}
.contact-form {
    
    max-width: 1200px;
    margin: 80px auto;
    padding: 20px;
    color: #272838;
    position: relative;
    z-index:2;
}

h1 {
    font-size: 40px;
    margin-bottom: 10px;
    text-align: center;
}

.highlight {
    color: #009ee2;
}

.subtitle {
    font-size: 14px;
    color: #656565;
    margin-bottom: 20px;
    text-align: center;

}

.form-row {
    display: flex;
    gap: 40px;
    margin-bottom: 20px;
}

.input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.full-width {
    width: 100%;
}

label {
    margin-bottom: 5px;
    font-size: 14px;
}

input,
textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    background-color: #D6D6D6;
    font-family: 'Poppins';
}

textarea {
    height: 250px;
    resize: vertical;
}

button {
    background-color: #009ee2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    font-family: 'Poppins';
}

button:hover {
    background-color: #0086bf;
}

@media screen and (max-width:768px) {

    input,
        textarea {
            width: 90%;
            padding: 15px;
            border: 1px solid #D6D6D6;
            border-radius: 4px;
            background-color: #D6D6D6;
        }
    .form-row {
            display: flex;
            gap: 10px;
            margin-bottom: 20px;
            flex-direction: column;
        }
    .contact-form {
         
            margin: 0px auto;
            padding: 10px;
           
            z-index: 2;
        }
                h1 {
                    line-height: 1;
                }
    
}
@media (prefers-color-scheme: dark) {
    .subtitle{
        color:#e2e2e2;
    }
.contact-form {
    color:#e2e2e2;
}
    #contact-form{
        background-color:#000;
        padding: 20px;
    }
    .fondo-sc{
        display: none;
    }
}
</style>