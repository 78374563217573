<template>
    <div>
        <h1>Eventos</h1>
        <div class="subtitle">Desde el concepto creativo hasta la ejecución final</div>
        <p>Nos encargamos de planificar y ejecutar íntegramente eventos que son mucho más que eso; experiencias
            inmersivas, donde el cliente puede vivir y conocer en primera persona las virtudes de tu marca.
        </p>
        <div class="image-container">
            <ImageSlider :carpeta="'btl/eventos'" />

           
        </div>
    </div>
</template>

<script>
import ImageSlider from '@/components/ImageSlider.vue'

    export default {
    components: {
        ImageSlider
    }
    }
</script>

<style lang="scss" scoped>
 h1 {
     color: #009ee2;
     font-size: 40px;
     text-align: center;
     margin-bottom: 10px;
     margin-top: 80px;
 }

 .subtitle {
     text-align: center;
     font-size: 18px;
     margin-bottom: 20px;
     font-weight: bold;
 }

 p {
     text-align: center;
     font-size: 16px;
     line-height: 1.2;
   
     max-width:1000px;
     display: block;
     margin:0 auto;
     color: #656565;
     
 }

 .image-container {
    display: block;
     width:96%;
     max-width: 1400px;
     margin: 30px auto;
     background: transparent !important;
 }

 img {
     width: 100%;
     height: auto;
     border-radius: 10px;
 }

 @media screen and (max-width:768px) {
    .image-container {
            display: block;
            width: 90%;
            max-width: 93%;
            margin: 10px auto;
            background: transparent !important;
        }
 }
 @media screen and (max-width: 768px) {
     h1 {
         color: #009ee2;
         font-size: 40px;
         text-align: left;
         margin-bottom: 10px;
         margin-top: 60px;
         margin-left: 20px;
         line-height: 1.2;
     }

     .subtitle {
         text-align: left;
         font-size: 18px;
         margin-bottom: 10px;
         font-weight: bold;
         margin-left: 20px;
            max-width: 90%;
     }

     p {
         text-align: left;
         font-size: 18px;
         line-height: 1.2;
         margin-left: 20px;

         max-width: 90%;
         display: block;
         margin: 0 auto;
         color: #656565;
         margin-left: 20px;

     }

    
     //         .image-container img{
     // height: 300px;
     //     object-fit: cover;
     //         }
     img {
         width: 100%;
         height: auto;
         border-radius: 10px;
     }
 }
  @media (prefers-color-scheme: dark) {
      #menu1 .image-container {
          background-color: #000;
          margin: 10px auto;
      }
    }
</style>