<template>
    <div id="servicios">
        <div class="services-container">
            <h2 class="section-title">Nuestros <span class="highlight">servicios</span></h2>
            <p class="section-description">Somos un equipo de profesionales capacitado y preparado para diseñar y
                ejecutar un plan de Marketing de primer nivel.

                <br> Proporcionamos soluciones a medida que abarcan desde la
                concepción creativa hasta la ejecución impecable.
            </p>

            <div class="services-grid">
                <div v-for="(service, index) in services" :key="index"
                    :class="['service-item', { 'highlight-background': index === 0 }]">
                    <div class="service-content">
                        <h3 class="service-title">{{ service.title }}</h3>
                        <p class="service-description" v-if="!$isMobile()">{{ service.description }}</p>
                        <p class="service-description" v-if="$isMobile()">{{ service.descriptionm }}</p>
                        <a :href="service.link" class="service-link">Más info</a>
                    </div>
                    <img :src="service.image" :alt="service.title" class="service-image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            services: [
                { title: 'ATL', 
                    description: 'Planificamos, diseñamos y ejecutamos campañas estratégicas, en planes de medios: Vía pública, TV, radios, etc.',
                    descriptionm: 'Planificamos, diseñamos y ejecutamos campañas...',
                 image: require('@/assets/home/s-atl.png'), link: '/servicios/atl' },
                { title: 'BTL', 
                    description: 'Realizamos activaciones de marca BTL, logrando que el consumidor experimente la marca a través de acciones creativas y significativas.',
                    descriptionm: 'Realizamos activaciones de marca BTL, logrando que el...',
                 image: require('@/assets/home/s-btl.png'), link: '/servicios/btl' },
                { title: 'Trade Marketing', 
                    description: 'Nos encargamos de instalar tu marca en los PDV. Hacemos que tu marca se destaque en los puntos de contacto con el consumidor.',
                    descriptionm: 'Nos encargamos de instalar tu marca en los PDV...',
                 image: require('@/assets/home/s-trade.png'), link: '/servicios/trade-marketing' },
                { title: 'Proyectos Especiales', 
                    description: 'Realizamos acciones creativas y eventos especiales, para abordar las necesidades específicas de marketing de cada cliente.',
                    descriptionm: 'Realizamos acciones creativas y eventos...',
                   image: require('@/assets/home/s-pro.png'), link: '/servicios/proyectos-especiales' },
                { title: 'Merchandising', 
                    description: 'Nos encargamos del diseño, producción, comercialización y distribución de elementos de branding.',
                    descriptionm: 'Nos encargamos del diseño, producción...',
                 image: require('@/assets/home/s-merch.png'), link: '/servicios/merchandising' },
                { title: 'Logística', 
                    description: 'Recibimos, almacenamos y distribuimos el material de tu marca. Nos encargamos del transporte y distribución efectiva y eficiente.',
                    descriptionm: 'Recibimos, almacenamos y distribuimos el material...',
                 image: require('@/assets/home/s-log.png'), link: '/servicios/logistica' },
            ],
        };
    },
};

</script>

<style scoped>
.services-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
    position: relative;
    z-index:2;
}

.section-title {
    font-size:40px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #272838;
}

.highlight {
    color: #098BCC;
}

.section-description {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 40px;
    color: #656565;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.service-item {
    background-color: #272838;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    position: relative;
    padding: 20px;
    padding-bottom: 40px;
    box-sizing: border-box;
    transition: all 0.2s;
box-shadow:
        1.7px 1.2px 1.8px rgba(0, 0, 0, 0.012),
        4.1px 2.9px 4.3px rgba(0, 0, 0, 0.017),
        7.6px 5.4px 8.1px rgba(0, 0, 0, 0.019),
        13.6px 9.6px 14.5px rgba(0, 0, 0, 0.021),
        25.5px 18px 27.2px rgba(0, 0, 0, 0.023),
        61px 43px 65px rgba(0, 0, 0, 0.03);
}

.service-item:hover{
    background-color: #098BCC;
    
}
/* Cambia el fondo del link cuando se hace hover en service-item */
.service-item:hover .service-link {
    background-color: #272838;
    /* Nuevo color de fondo al hacer hover */
}

.service-content {
    /* flex: 1; */
    padding-right: 20px;
    text-align: left;
    width: 50%;
}

.service-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.service-description {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 200;
    height: 100px;
}

.service-link {
    display: inline-block;
    padding: 6px 16px;
    background-color: #098BCC;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
}

.service-image {
width: 44%;
    height: 238px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    right: 10px;
    top: 10px;
    /* margin-right: 10px; */
    border-radius: 8px;
}

@media screen and (max-width: 768px) {
    .services-grid {
        grid-template-columns: 1fr;
    }

    .service-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .service-content {
        padding-right: 0;
        width: 66%;
    }
        

    .service-image {
        /* position: static; */
        transform: none;
        width: 30%;
        height: 89%;
        margin-top: 0px;
    }
    .section-title{
        line-height: 1;
    }
        .service-description {
            font-size: 14px;
            margin-bottom: 20px;
            font-weight: 200;
            height: auto;
        }
}
@media (prefers-color-scheme: dark) {
    #servicios{
        background-color: #000;
    }
    .section-title{
        color:#e2e2e2
    }
    .section-description{
        color: #e2e2e2;
    }
}
</style>
