<template>
    <div class="galeria-content">
        <div class="log-galeria">
            <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop" @slideChange="onSlideChange">
                <swiper-slide v-for="(image, index) in images" :key="index"
                    :style="'background:url(' + `/images/${carpeta}/${image}` + ')'" ></swiper-slide>

                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            </swiper>
        </div>

        <div class="mini">
            <div class="image-grid">
                <div class="grid-item" v-for="(image, index) in images" :key="index"
                    :class="{ active: activeIndex === index }"
                    :style="'background-image: url(' + `/images/${carpeta}/${image}` + ')'" @click="goToSlide(index + 1)">
                </div>
            </div>
        </div>
        <div class="galeria-footer"></div>
    </div>
</template>

<script>
export default {
    props: ['carpeta'],
    data() {
        return {
            images: Array.from({ length: 12 }, (_, i) => `${i + 1}.jpg`),
            swiperOptionTop: {
                spaceBetween: 20,
                effect: "fade",
                loop: true,
                autoplay: {
                    delay: 3000, // Cambia el valor a la cantidad de milisegundos que prefieras
                    disableOnInteraction: false, // Esto permite que el autoplay continúe después de la interacción
                },
                
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            },
            activeIndex: 0
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.swiperTop = this.$refs.swiperTop.swiper;
        });
    },
    methods: {
        goToSlide(index) {
            // this.swiperTop.slideTo(index, 0);
            this.$refs.swiperTop.$swiper.slideTo(index, 0)
        },
        onSlideChange() {
        
            this.activeIndex = this.$refs.swiperTop.$swiper.realIndex;
        }
    }
};
</script>

<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 20px;
    height: 600px;
}

.grid-item {
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border-radius: 12px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}

.grid-item.active {
    filter: grayscale(0%);
}

.mini {
    width: 39%;
    float: left;
    padding: 20px;
    padding-right: 0;
}

.log-galeria {
    width: 59%;
    height: 600px;
    float: left;
    margin-top: 20px;
    border-radius: 8px;
    background: ;
}

.galeria-footer {
    background: #FFF;
    height: 600px;
    margin-bottom: 200px;
}

.galeria-content {
    margin: 0 auto;
    margin-bottom: 150px;
}

.swiper-container {
    background-color: transparent;
}

.swiper-slide {
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: repeat-x !important;
}

.gallery-top {
    height: 100% !important;
    width: 100%;
    margin: 0 auto;
    border: 2px #333;
    border-radius: 8px;
    background-size: cover;
}

@media screen and (max-width: 1056px) {
    .mini {
        width: 100%;
        margin-top: 20px;
        padding:0;
        margin-bottom: 30px;
    }

    .log-galeria {
        width: 100%;
        height: 50vh;
    }

   .image-grid {
       display: grid;
       grid-template-columns: repeat(3, 1fr);
       grid-template-rows: repeat(4, 1fr);
       gap: 10px;
       height: 400px;
   }

   .grid-item {
       background-size: cover;
       background-position: center;
       cursor: pointer;
       border-radius: 12px;
       filter: grayscale(100%);
       transition: filter 0.3s ease;
   }
.galeria-footer {
    background: #FFF;
    height: 10px;
    margin-bottom: 10px;
}
}
</style>
