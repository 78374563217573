<template>
    <div class="bg-dark">
        <div class="container">
            <div class="text-content">
                <h1>Logramos <br> que tu marca <br> <span class="highlight">se luzca</span></h1>
                <p>Nos encargamos del diseño, producción, comercialización y distribución de elementos de branding.</p>
                <a href="#" class="cta-button" v-scroll-to="'#contact-form'">Contratar el servicio</a>
            </div>
            <div class="image-grid" v-if="!$isMobile()">
                <div class="image-card" v-scroll-to="'#menu1'">
                    <div class="image-label">Catálogo</div>
                    <img src="@/assets/merch/catalogo.jpg">
                </div>
                <div class="image-card" v-scroll-to="'#menu2'">
                    <div class="image-label">Diseños personalizados</div>
                    <img src="@/assets/merch/disenio.jpg">
                </div>
                <div class="image-card" v-scroll-to="'#menu3'">
                    <div class="image-label">Producción</div>
                    <img src="@/assets/merch/produccion.jpg">
                </div>
                <div class="image-card" v-scroll-to="'#menu4'">
                    <div class="image-label">Distribución</div>
                    <img src="@/assets/merch/distri.jpg">
                </div>
            </div>
        </div>
        <div v-if="$isMobile() " class="menu-m">
            <img src="@/assets/merch/menu-1.png" v-scroll-to="'#menu1'">
            <img src="@/assets/merch/menu-2.png" v-scroll-to="'#menu2'">
            <img src="@/assets/merch/menu-3.png" v-scroll-to="'#menu3'">
            <img src="@/assets/merch/menu-4.png" v-scroll-to="'#menu4'">
        </div>
        <div class="menu-bar">
            <ul class="menu">
                <li><a href="#" v-scroll-to="'#menu1'">Catálogo</a></li>
                <li><a href="#" v-scroll-to="'#menu2'">Diseños personalizados</a></li>
                <li><a href="#" v-scroll-to="'#menu3'">Producción</a></li>
                <li><a href="#" v-scroll-to="'#menu4'">Distribución</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.menu-bar {
    background-color: #098BCC;
    padding: 10px 0;
}

.menu {
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu li {
    flex: 1;
    text-align: center;
}

.menu a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    display: block;
    padding: 10px 0;
    transition: all 0.2s
}
.menu a:hover {
    color: white;
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    display: block;
    padding: 10px 0;
    transition: all 0.2s
}
.container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    display: flex;
    align-items: center;
}

.text-content {
    flex: 0 0 31%;
    font-weight: 300;
    /* Ancho del 30% */
}

h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #272838;
    line-height: 1.2;
}

.highlight {
    color: #098BCC;
}

p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.cta-button {
    display: inline-block;
    background-color: #098BCC;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
}

.image-grid {
    display: flex;
    gap: 20px;
    flex: 0 0 69%;
    /* Ancho del 70% */
}

.image-card {
    flex: 1;
    background-color: #272838;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
}

.image-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s;
    z-index: 0;
}
.image-card img:hover {
    transform: scale(1.03);
    z-index: 0;
}

.image-label {
    color: white;
    padding: 10px;
    text-align: center;
    height: 34px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Céntrate verticalmente */
    line-height: 1.1;
    z-index: 10;
}
@media screen and (max-width: 768px) {
    .menu-bar {
            background-color: #098BCC;
            padding: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .menu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    
        .menu li {
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            /* Añadir espacio entre los elementos del menú */
        }
    
        .menu a {
            color: white;
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
            display: block;
            padding: 10px 0;
            transition: all 0.2s;
            width: 100%;
        }
    
        .menu a:hover {
            color: white;
            text-decoration: underline;
            font-weight: 500;
            font-size: 16px;
            display: block;
            padding: 10px 0;
            transition: all 0.2s;
        }
    
    .cta-button {
        margin-bottom: 10px;
    }
    .menu-m {
        max-width: 90%;
        margin: 0 auto;
            margin-bottom: 60px;
    }
         .menu-m img{
            display: block;
            width: 100%;
            margin: 0 auto;
            cursor: pointer;
            margin-bottom: 20px;
         }
    .container {
        flex-direction: column;
        /* Cambia la dirección del contenido a una columna */
        align-items: flex-start;
        margin:0 auto;
        /* Alinea los elementos al principio */
    }

    .text-content {
        flex: 0 0 100%;
        /* Haz que ocupe el 100% del ancho */
        margin-bottom: 20px;
        /* Añade un margen inferior para separar el contenido del grid de imágenes */
    }
         .text-content p{
            font-size: 20px;
            font-weight: 300;
         }

    .image-grid {
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        /* Cambia la dirección de las imágenes a columna */
        gap: 10px;
        /* Ajusta el espacio entre las imágenes */
    }

    .image-card {
        display: flex;
        flex-direction: row;
        /* Coloca la imagen a la derecha y el texto a la izquierda */
        align-items: center;
        gap: 10px;
        /* Espacio entre la imagen y el texto */
        padding: 10px;
        background-color: #272838;
        border-radius: 10px;
    }

    .image-card img {
        flex: 0 0 auto;
        width: 80%;
        height: 108px;
        /* Fija la altura de la imagen a 108px */
        object-fit: cover;
        /* Ajusta la imagen para que cubra el área */
    }

    .image-label {
        flex: 1;
        color: white;
        font-size: 14px;
        text-align: left;
        /* Alinea el texto a la izquierda */
        padding: 0;
        /* Elimina el padding superior e inferior */
    }

    h1 {
        font-size: 32px;
        /* Ajusta el tamaño de la fuente del h1 para pantallas más pequeñas */
    }

    p {
        font-size: 16px;
        /* Ajusta el tamaño de la fuente del párrafo para pantallas más pequeñas */
    }
}
@media (prefers-color-scheme: dark) {
    .bg-dark {
        background-color: #000;
            padding: 20px 0px;
    }

    .text-content {
        color: #e2e2e2;
    }

    h1 {
        color: #e2e2e2;
    }
}
</style>
