import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/global.css'; // Importa el archivo CSS global
import VueMobileDetection from "vue-mobile-detection";

import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueScrollTo from 'vue-scrollto'
// import { initializeFontAdjustment } from './fontAdjustment'
import { Simplert } from 'vue2-simplert-plugin';
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css';

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
};


Vue.use(Toast, options);
Vue.use(Simplert, {
  
  customCloseBtnText: 'Aceptar', //string -- close button text
  customCloseBtnClass: 'botonAlert', //string -- custom class for close button
 
});
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.config.productionTip = false
Vue.use(VueMobileDetection);
Vue.use(VueScrollTo)
// initializeFontAdjustment()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
