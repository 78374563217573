<template>
    <section class="hero" id="hometop" :class="{ 'loaded': imageLoaded }">
        <div class="container">
            <div class="hero-content">
                <h1>
                    ¡Let's make <br v-if="!this.$isMobile()">
                    great <br v-if="this.$isMobile()"> things<br v-if="!this.$isMobile()">
                    <span class="highlight"> happen</span>!
                </h1>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            imageLoaded: false,

        };
    },
    mounted() {
        const img = new Image();
        img.src = this.highQualityImage;
        img.onload = () => {
            this.imageLoaded = true;
        };
    },
};
</script>
<style scoped>
.hero {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
        url('@/assets/portada.jpeg') no-repeat center center;
    background-size: cover;
    color: #fff;
    padding: 6rem 2rem;
    font-family: 'Poppins', sans-serif;
    min-height: calc(100vh - 330px);
    transition: background-image 0.5s ease;
}

.hero.loaded {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
        url('@/assets/portada.jpeg');
}

.container {
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 130px;
}

.hero-content {
    text-align: left;
    position: relative;
    z-index: 2;
    max-width: 100%;
}

.hero-content h1 {
    font-size: 5rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    white-space: pre-line;
    font-weight: 900;
    font-style: italic;
    margin: 0;
}

.hero-content h1 .highlight {
    color: var(--azulOscuro);
}

@media (max-width: 768px) {
    .hero {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),
            url('@/assets/home/portada-mobile.png') no-repeat center center;
        background-size: cover;
        padding: 10px;
        min-height: calc(100vh - 170px);
    }

    .hero.loaded {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),
            url('@/assets/home/portada-mobile.png');
    }

    .container {
        margin-top: 0px;
    }
.hero-content{
    margin:0 auto;
}
    .hero-content h1 {
        font-size: 36px;
        text-align: center;
    }
}
</style>
