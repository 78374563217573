<template>
  <header>
    <nav>
      <div class="logo">
        <a href="/" title="Volver al home"><img src="@/assets/logo-quo.png" alt="Logo"></a>
      </div>
      <div :class="['menu-toggle', { 'open': menuOpen }]" @click="toggleMenu">
        <div class="bar" :class="{ 'open': menuOpen }"></div>
        <div class="bar" :class="{ 'open': menuOpen }"></div>
        <div class="bar" :class="{ 'open': menuOpen }"></div>
      </div>
      <ul :class="{ 'menu-open': menuOpen }">

        <li><a href="#" @click="navigateAndScroll1" v-if="$isMobile() ">Home</a></li>
        <li><a href="#" @click="navigateAndScroll">Sobre Nosotros</a></li>
        <li class="dropdown" @mouseover="openDropdown" v-if="!$isMobile() ">
          <a href="#" v-scroll-to="'#servicios'">Nuestros Servicios</a>
          <ul :class="{ 'dropdown-content-open': dropdownOpen }" @mouseleave="closeDropdown" class="dropdown-content">
            <li><a href="/servicios/atl">ATL</a></li>
            <li><a href=" /servicios/btl">BTL</a></li>
            <li><a href="/servicios/trade-marketing">Trade Marketing</a></li>
            <li><a href="/servicios/proyectos-especiales">Proyectos Especiales</a></li>
            <li><a href="/servicios/merchandising">Merchandising</a></li>
            <li><a href="/servicios/logistica">Logística</a></li>
          </ul>
        </li>
        <li class="dropdown" @mouseover="openDropdown" v-if="$isMobile()">
          <a href="#">Nuestros Servicios</a>
          <ul :class="{ 'dropdown-content-open': dropdownOpen }" @mouseleave="closeDropdown" class="dropdown-content">
            <li><a href="/servicios/atl">ATL</a></li>
            <li><a href=" /servicios/btl">BTL</a></li>
            <li><a href="/servicios/trade-marketing">Trade Marketing</a></li>
            <li><a href="/servicios/proyectos-especiales">Proyectos Especiales</a></li>
            <li><a href="/servicios/merchandising">Merchandising</a></li>
            <li><a href="/servicios/logistica">Logística</a></li>
          </ul>
        </li>
        <li><a href="#" v-scroll-to="'#contact-form'" class="contact-button">Contáctanos</a></li>
      </ul>
    </nav>

  </header>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      dropdownOpen: false,
      showScrollButton: false,


    };
  },
  methods: {
    navigateAndScroll1() {
      if (this.$route.name === 'home') {
        this.$scrollTo('#hometop', 500);
       this.closeAll()
      }
      else {
        this.$router.push({ name: 'home' }).then(() => {
          this.$scrollTo('#hometop', 500);
         this.closeAll()
        });
      }

    },
    navigateAndScroll() {
      if(this.$route.name === 'home')
    {
        this.$scrollTo('#about', 500);
       this.closeAll()
    }
    else{
        this.$router.push({ name: 'home' }).then(() => {
          this.$scrollTo('#about', 500);
         this.closeAll()
        });
    }
   
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      if (!this.menuOpen) {
        this.dropdownOpen = false; // Cierra el dropdown cuando el menú se cierra
      }
    },
    openDropdown() {
      this.dropdownOpen = true;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    closeAll() {
      this.menuOpen = false;
      this.dropdownOpen = false;
    }
  },
  // mounted() {
  //   document.addEventListener('click', this.closeAll);
  // },
  // beforeDestroy() {
  //   document.removeEventListener('click', this.closeAll);
  // }
};
</script>

<style scoped>
header {

  padding: 1.2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;

  margin: 0 auto;
  position: relative;
  z-index: 1000;
  /* Asegura que el header esté por encima de otros elementos */
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

nav .logo a>img {
  display: block;
  height: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0;
  padding: 0;
}

nav li a {
  text-decoration: none;
  color: #272838;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: color 0.3s;
  font-size: 14px;
}

nav li a:hover {
  color: var(--azulquo);
}

nav li.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 350px;
  z-index: 1;
  flex-direction: column;
  padding: 0;
  gap: 0rem;
  margin-top: 19px;
  transition: all 0.2s;
}

.dropdown-content li {
  padding: 0px;
  width: 100%;
  font-size: 14px;
}

.dropdown-content li a {
  color: #272838;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1.2rem;
  font-size: 14px;

  text-transform: uppercase;
}

.dropdown-content li a:hover {
  background-color: var(--azulquo);
  color: white;
}

.dropdown-content-open {
  display: flex !important;
}

.contact-button {
  background-color: var(--azulquo);
  color: white;
  padding: 0.5rem 1.2rem;
  border-radius: 8px;
  transition: background-color 0.3s;
  font-weight: normal;
}

.contact-button:hover {
  background-color: #0056b3;
    color: white;
}

/* Mobile Styles */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle .bar {
  width: 40px;
  height: 3px;
  background-color: #272838;
  margin: 4px 0;
  transition: 0.4s;
}

.menu-toggle.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(10px, -10px);
}

@media (max-width: 768px) {
      .dropdown-content-open{
          display: block !important;
          margin-top: 0;
        }
  .menu-toggle {
    display: flex;
  }

  header{
    padding:20px;
  }
  nav ul {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: none;
    z-index: 1000;
    /* Asegura que el menú esté por encima de otros elementos */
  }

  nav ul.menu-open {
    display: flex;
    width: 100%;
    background: #fff;
    border: none;
    padding: 0px 2px;
    margin: 0;
    border-top: 1px solid #fafafa;
    margin-left: -2px;
    gap: 0;
  }

  nav li {
    width: 100%;
    text-align: left;
  }

  nav li a {
    padding: 1rem;
    width: 100%;
    display: block;
    font-size: 14px;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    background-color: transparent;
    display: none;
  }

  .dropdown-content-open {
    display: block !important;
  }
    .contact-button {
      background-color: var(--azulquo);
      color: white;
      padding: 0.5rem 1.2rem;
      border-radius: 0px;
      transition: background-color 0.3s;
      font-weight: normal;
    }
}

.logo img {
  content: url('@/assets/logo-quo.png');
}

/* Estilos para dispositivos en modo oscuro */
@media (prefers-color-scheme: dark) {
  .logo img {
    content: url('@/assets/logo-quo-white.png');
  }
}
@media (prefers-color-scheme: dark) {
    nav ul.menu-open {
        display: flex;
        width: 100%;
        background: #000;
        border: none;
        padding: 0px 2px;
        margin: 0;
        border-top: 1px solid #000;
        margin-left: -2px;
        gap: 0;
      }
    

  header {
    background-color: #098BCC;
  }

  nav li a {

    color: #FFF;

  }
    .dropdown-content  {
      background-color: #000;
    }
.dropdown-content li a {
  color: #e2e2e2;
}
  .contact-button{
      background-color: #40B6E6;
      border-color: #40B6E6;
  }
    .contact-button:hover {
      background-color: #40B6E6;
      color: white;
    }
}
</style>
