<template>
  <div class="admin-container">
    <div class="admin-sidebar">
      <ul>
        <li><router-link to="/admin/datos">Datos</router-link></li>
        <li><router-link to="/admin/mensajes">Mensajes</router-link></li>
        <li><router-link to="/admin/textos">Textos</router-link></li>
        <li><router-link to="/admin/imagenes">Imagenes</router-link></li>
        <li><router-link to="/admin/galerias">Galerias</router-link></li>
        <!-- <li><router-link to="/admin/btl">btl</router-link></li>
        <li><router-link to="/admin/trade">trade</router-link></li>
        <li><router-link to="/admin/proyectos">proyectos</router-link></li>
        <li><router-link to="/admin/merchandising">merchandising</router-link></li>
        <li><router-link to="/admin/logistica">logistica</router-link></li> -->
        <li><a href="#" @click.prevent="logout">Salir</a></li>
      </ul>
    </div>
    <div class="admin-content">
      <!-- Aquí se renderizan las rutas hijas del admin -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Admin',
  methods: {
    logout() {
      window.sessionStorage.removeItem('authQuo');
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.admin-container {
  display: flex;
  height: 100vh;
}

.admin-sidebar {
  width: 200px;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.admin-sidebar li {
  margin-bottom: 10px;
}

.admin-sidebar a {
  color: white;
  text-decoration: none;
}

.admin-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #ecf0f1;
}
</style>
