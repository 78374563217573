<template>
    <div>
        <h1>Logística</h1>
        <div class="subtitle">Nos encargamos del transporte y distribución efectiva y eficiente de materiales.</div>
        <p>Ofrecemos un servicio integral de logística de materiales, asegurando que lleguen a su destino final en
            tiempo y forma.
            Utilizamos tecnologías avanzadas y estrategias innovadoras para garantizar la entrega puntual de productos,
            minimizando los costos, y maximizando la eficiencia en cada etapa.
        </p>
        <div class="image-container">
            <img src="@/assets/log/log-portada.jpg" v-if="!$isMobile()"
                alt="Nos encargamos del transporte y distribución efectiva y eficiente de materiales">
            <img src="@/assets/log/log-portada-m.jpg" v-if="$isMobile()"
                alt="Nos encargamos del transporte y distribución efectiva y eficiente de materiales">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
h1 {
    color: #009ee2;
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 60px;
}

.subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
}

p {
    text-align: center;
    font-size: 16px;
    line-height: 1.2;

    max-width: 1000px;
    display: block;
    margin: 0 auto;
    color: #656565;

}

.image-container {
    display: block;
    width: 100%;
    max-width: 1400px;
    margin: 20px auto;
}

img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    h1 {
        color: #009ee2;
        font-size: 40px;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 60px;
        margin-left: 20px;
        line-height: 1.2;
    }

    .subtitle {
        text-align: left;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
        margin-left: 20px;
    }

    p {
        text-align: left;
        font-size: 18px;
        line-height: 1.2;
        margin-left: 20px;

        max-width: 90%;
        display: block;
        margin: 0 auto;
        color: #656565;
        margin-left: 20px;

    }

    .image-container {
        display: block;
        width: 90%;
        max-width: 1000px;
        margin: 20px auto;

    }

    //         .image-container img{
    // height: 300px;
    //     object-fit: cover;
    //         }
    img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
}
</style>