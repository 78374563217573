<template>
    <div>
<h1>Sitio QuoMarketing</h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>